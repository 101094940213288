import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'

function Loader({ className, height, width, speed }) {
  return (
    <ContentLoader
      viewBox="0 0 400 160"
      height={height ? height : 160}
      width={width ? width : 400}
      speed={speed ? speed : 1}
      backgroundColor="transparent"
      className={className ? className : 'pb-10 mx-auto'}
    >
      <circle cx="126" cy="20" r="20" />
      <circle cx="194" cy="20" r="20" />
      <circle cx="262" cy="20" r="20" />
    </ContentLoader>
  )
}

Loader.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  speed: PropTypes.string,
}

export default Loader
