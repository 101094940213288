import React from 'react'
import Logo from '../images/logo.inline.svg'

import ContentLoader from '../components/content-loader/content-loader'

function ChatLoading() {
  return (
    <div className="text-center">
      <div className="mb-10 p-4" style={{ backgroundColor: '#04b2e1' }}>
        <Logo className="inline-block bg-white" />
      </div>
      <p className="mb-5 text-gray-700">Live Chat is loading. Please wait...</p>
      <ContentLoader />
    </div>
  )
}

export default ChatLoading
